import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation'; // Se estiver usando a navegação, adicione módulos necessários
import 'swiper/css/pagination'; // Se estiver usando a paginação
import { FaInstagram, FaWhatsapp, } from 'react-icons/fa'; // Ícones de redes sociais

const Inicio: React.FC = () => {
  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen z-50">
      

    {/* <div className="flex flex-col items-center justify-between min-h-screen"> */}
      {/* Logo */}
      <div className="my-4 mx-6 md:mx-0 text-center">
        <img
          src="/logo_branca.svg" // Substitua pelo caminho da sua logo
          alt="Logo PrintTres"
          className="w-64 mx-auto"
        />
        <div className='mb-10'>
          <h1 className='md:text-4xl text-white font-bold text-center text-wrap mt-8'>Três Dimensões de Inovação</h1>
          <h2 className='md:text-2xl text-white font-medium text-center text-wrap'>Brindes, camisetas, impressões 3D e muito mais!</h2>
        </div>
        <a
          href="./Catálogo - Print3 2024.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className='bg-white inline-block rounded-full py-2 px-6 font-medium transition-all duration-300 hover:-translate-y-2 hover:shadow text-xl text-primary text-center'>
            Confira nosso catálogo completo aqui
        </a>
      </div>

      {/* Ícones de Redes Sociais */}
      <div className="mt-8 flex space-x-4">
        <a
            href="https://api.whatsapp.com/send/?phone=5511998837498&text&type=phone_number&app_absent=0"
            target="_blank"
            rel="noopener noreferrer"
            className='bg-white rounded-full p-3 transition-all duration-300 hover:-translate-y-2 hover:shadow'>
                <FaWhatsapp className="text-3xl text-primary" />
        </a>
        <a
            href="https://www.instagram.com/print.tres/"
            target="_blank"
            rel="noopener noreferrer"
            className='bg-white rounded-full p-3 transition-all duration-300 hover:-translate-y-2 hover:shadow'>
                <FaInstagram className="text-3xl text-primary" />
        </a>
      </div>
    </div>
  );
};

export default Inicio;
