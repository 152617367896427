import React from 'react';
import Inicio from './pages/Inicio';

function App() {
  return (
    <div className='bg-primary'>
      <div className="absolute inset-0 bg-gradient-to-br from-primary_dark via-primary_medium to-primary_dark bg-[length:300%_300%] animate-slide" />
      <div className="absolute inset-0 animate-slide" style={{
        backgroundImage: 'url(/fundo.png)',
        backgroundRepeat: 'repeat',
        backgroundSize: '300px 300px'
      }} />
      <Inicio />
    </div>
  );
}

export default App;
